<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div class="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">

      <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
           :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div class="login-form text-center text-white p-7 position-relative overflow-hidden">
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-5">
            <a href="#">
              <img src="media/login/logo_rcc_new.png" class="max-h-75px" alt=""/>
            </a>
          </div>
          <!--end::Login Header-->
          <router-view/>
          <div class="d-flex flex-center mt-15">
            <a href="#">
                <inline-svg src="media/login/afs_infotech.svg" style="width: 60%" />
              <!--              <img src="media/login/logo_almastech.svg" class="max-h-75px" alt="" />-->
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--end::Login-->
  </div>
</template>
<style scoped>
.login-title {
  position: relative;
  top: -136px;
  text-align: center;
}

.cls-1, .cls-6 {
  fill: #efefef;
  stroke-width: 5px;
}

.cls-1, .cls-5, .cls-6 {
  stroke-miterlimit: 10;
}

.cls-1 {
  stroke: url(#linear-gradient);
}

.cls-2 {
  font-size: 91.41px;
  fill: #111;
  font-family: Poppins-Regular, Poppins;
}

.cls-3 {
  font-size: 85.42px;
  font-family: Comfortaa-Bold, Comfortaa;
  font-weight: 700;
}

.cls-4 {
  letter-spacing: -0.03em;
}

.cls-5 {
  fill: none;
  stroke: #000;
  stroke-width: 4px;
}

.cls-6 {
  stroke: url(#linear-gradient-2);
}

.cls-7 {
  opacity: 0.5;
}
</style>
<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "auth",
  mounted() {
    // check if current user is authenticated
    if (this.isAuthenticated) {
      this.$router.push({name: "dashboard"});
    }
  },
  methods: {},
  computed: {
    ...mapGetters([
      "isAuthenticated"
    ]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/login/bg-login-new.jpg";
    },
    backgroundImageRight() {
      return process.env.BASE_URL + "media/bg/login_bg_right.jpg";
    }
  }
};
</script>
